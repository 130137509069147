import { Select as AntdSelect } from 'antd';
import './PanelContent.css';
import { useCallback, useEffect, useState } from 'react';

export function PanelContent(props) {
  const mappedData = props.items.mappedData;
  const inputData = props.items.inputData ?? [];

  let { setSkuJSON, skuJson, attemptedSubmit } = props;

  useEffect(() => {
    setSkuJSON({...mappedData});
  }, [mappedData]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setSkuJSON({
      ...skuJson,
      [e.target.name]: e.target.value,
    });
  }

  const handleMultiSelect = (values, key) => {
    setSkuJSON({
      ...skuJson,
      [key]: values.join(','),
    });
    console.log(skuJson)
  }

  const shouldIgnoreFieldWithAttributeName = (attrNames) => {
    for (let n of ['Image ', 'image']) {
      if (attrNames?.includes(n)) {
        return true;
      }
    }
  }

  return (
    <div className="panel-content">
      <form>
        {
          inputData.map((obj) => {
            const isMandatory = !obj.process_rule.optional;
            const hasMinCount =  obj.process_rule.min<(skuJson[obj.attributeName]??'').toString().split(" ").length;
            const isEmpty = !skuJson[obj.attributeName] && skuJson[obj.attributeName] !== 0;
            let showWarningBorder;
            if(obj.process_rule.rule === 'write'){
              showWarningBorder = attemptedSubmit && isEmpty && !hasMinCount;
            }else{
              showWarningBorder = attemptedSubmit && isEmpty && isMandatory;
            }

            if (shouldIgnoreFieldWithAttributeName(obj.attributeName)) {
              return <span key={obj.attributeName}></span>;
            }
            switch (obj.process_rule.rule) {
              case 'single_select':
                return <div className='select-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>
                    {obj.attributeName}
                    {!obj.process_rule.optional && <span>*</span>}
                  </label>
                  {/* <Select
                    value={obj.attributeValues.option.optionValue}
                    options={obj.attributeValues}
                    defaultValue={options[1]}
                  /> */}
                  <select id={obj.attributeName} className={`select-field ${showWarningBorder && 'danger-input'}`} name={obj.attributeName}
                          onChange={handleInputChange} value={skuJson[obj.attributeName] || ''}>
                    <option value=''/>
                    {
                      obj.attributeValues.map((option) => {
                        return <option key={option.optionValue} value={option.optionValue}>{option.optionValue}</option>
                      })
                    }
                  </select>
                </div>
              case 'text_area':
                return <div className='text-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>{obj.attributeName}{!obj.process_rule.optional &&
                    <span>*</span>}</label>
                  <input placeholder="Type Here" className={`${showWarningBorder && 'danger-input'}`} name={obj.attributeName} onChange={handleInputChange}
                         value={skuJson[obj.attributeName]}/>
                </div>
              case 'input_text':
                return <div className='text-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>{obj.attributeName.replace('Product weight (gms)', 'Net Weight (gms)')}{!obj.process_rule.optional &&
                    <span>*</span>}</label>
                  <textarea placeholder="Type Here" className={`${showWarningBorder && 'danger-input'}`} rows="3" cols="10" name={obj.attributeName}
                            onChange={handleInputChange} value={skuJson[obj.attributeName]??""}/>
                </div>
              case 'input_number':
                return <div className='text-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>{obj.attributeName}{!obj.process_rule.optional &&
                    <span>*</span>}</label>
                  <input placeholder="Type Here" type={'number'} className={`${showWarningBorder && 'danger-input'}`} name={obj.attributeName} onChange={handleInputChange}
                         value={skuJson[obj.attributeName]}/>
                </div>
              case 'write':
                return <div className='text-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>{obj.attributeName}{!obj.process_rule.optional &&
                    <span>*</span>}</label>
                  <LimitedWordTextarea rows={'4'} cols={'50'} name={obj.attributeName} lowerLimit={obj.process_rule.min}
                                       upperLimit={obj.process_rule.max} value={skuJson[obj.attributeName]}
                                       onChange={handleInputChange}
                                       className={`${showWarningBorder && 'danger-input'}`}
                  />
                  {/*<textarea placeholder="Type Here" rows="4" cols="50" name={obj.attributeName} onChange={handleInputChange} value={skuJson[obj.attributeName]}/>*/}
                </div>
              case 'multi_select':
                return <div className='select-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>
                    {obj.attributeName}
                    {!obj.process_rule.optional && <span>{!obj.process_rule.optional && <span>*</span>}</span>}
                  </label>
                  {/* <Select
                    value={obj.attributeValues.option.optionValue}
                    options={obj.attributeValues}
                    defaultValue={options[1]}
                  /> */}
                  <AntdSelect mode={'multiple'} id={obj.attributeName} className={`select-field ${showWarningBorder && 'danger-input'}`}
                              value={skuJson[obj.attributeName].split(',')}
                              onChange={(v) => handleMultiSelect(v, obj.attributeName)}>
                    {
                      obj.attributeValues.map((option) => {
                        return <option key={option.optionValue} value={option.optionValue}>{option.optionValue}</option>
                      })
                    }
                  </AntdSelect>
                </div>
              default:
                return <div className='text-div' key={obj.attributeName}>
                  <label htmlFor={obj.attributeName}>{obj.attributeName}{!obj.process_rule.optional &&
                    <span>*</span>}</label>
                  <textarea placeholder="Type Here" className={`${showWarningBorder && 'danger-input'}`} rows="4" cols="50" name={obj.attributeName}
                            onChange={handleInputChange} value={skuJson[obj.attributeName]}/>
                </div>
            }
          })
        }
      </form>
    </div>
  )
}


const LimitedWordTextarea = ({ rows, cols, value, lowerLimit, upperLimit, name, onChange, className }) => {
  const [{ content, wordCount }, setContent] = useState({
    content: value,
    wordCount: value ? value.toString().split(' ').length : 0,
  });

  const [lessWordAlert, setLessWordAlert] = useState(false);

  const setFormattedContent = useCallback(
    text => {
      text = text ? text.toString() : '';
      let words = text ? text?.split(' ')?.filter(Boolean) : [];
      if (words.length > upperLimit && upperLimit != null) {
        setContent({
          content: words.slice(0, upperLimit).join(' '),
          wordCount: upperLimit
        });
      } else {
        //console.log(words.length, lowerLimit)
        if (words.length < lowerLimit && lowerLimit != null) {
          setLessWordAlert(true);
          //console.log('setting true');
        } else {
          setLessWordAlert(false);
          //console.log('setting false');
        }
        setContent({ content: text, wordCount: words.length });
      }
    },
    [upperLimit, lowerLimit, setContent]
  );

  useEffect(() => {
    setFormattedContent(content);
  }, []);

  useEffect(() => {
    setContent({
      content: value,
      wordCount: value ? value.toString().split(' ').length : 0,
    })
  }, [value]);

  return (
    <>
      <textarea
        rows={rows}
        cols={cols}
        name={name}
        className={className}
        onChange={event => {
          setFormattedContent(event.target.value);
          onChange(event);
        }}
        value={content}
      />
      {upperLimit != null && <p>
        {wordCount}/{upperLimit}
      </p>}
      {lowerLimit != null && lessWordAlert && <p className='lessWordAlert'>
        Minimum {lowerLimit} word{lowerLimit > 1 && 's'}
      </p>}
      {/* { !lessWordAlert} */}
    </>
  );
};
