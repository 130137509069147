import { axiosConfig } from './config/axios';
import axios from 'axios';
import { httpClient } from '.';

async function fetchAllTemplateData(input, output){
    return await httpClient.post(`/api/outputformat/fetchTemplateData?sclient=${input}&dclient=${output}`);
}

async function fetchTemplateDataUsingAt(file, input, output){
    console.log("fdf", input,output);
    return await httpClient.post(`/api/outputformat/getTemplateDataUsingArticles?sclient=${input}&dclient=${output}`, file);
}

export const allTemplateService ={
    fetchAllTemplateData,
    fetchTemplateDataUsingAt
}