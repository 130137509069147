import { Button, Steps } from 'antd';
import { DownloadOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { orderService } from '../../http/order';
import { useOrder } from '../../hooks/order.hook';
import { Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import ExitIcon from '../../static/images/exit_icon.svg';
import { ExitModal } from '../ExitModal/ExitModal';
import { useRecoilState } from 'recoil';
import { qcFilterState } from '../../atoms/qc.atom';


export default function Processing(props) {
  const [status, setStatus] = useState({});
  const [fileUrls, setFileUrls] = useState([]);
  const { order, batches, isLoading, refresh } = useOrder();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [qcFilter, setQcState] = useRecoilState(qcFilterState);
  const navigate = useNavigate();
  // const { order, batches, isLoading, refresh } = useOrder();


  useEffect(() => {
    const interval = setInterval(() => {
      if (order._id) {
        const oldobj = JSON.parse(localStorage.getItem("userFlowObj"));
        const userFlowObj = {
          ...oldobj, projectId: order._id, flow_index: 1
        };

        localStorage.setItem("userFlowObj", JSON.stringify(userFlowObj));
        orderService.getStatus(order._id).then((status) => {
          setStatus(status.data);
          if (status.data.orderStatus === 'MAPPING-COMPLETED') {
            orderService.getOutputFiles(order._id).then((val) => {
              setFileUrls(val.data);
            });
            clearInterval(interval);
          }
        });
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [order, batches]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    navigate("/");
  };

  const handleCancel = () => {

    setIsModalVisible(false);
  };

  useEffect(() => {
    orderService.getOutputFiles(order?._id).then((val) => {
      setFileUrls(val.data);
    });
  }, [status, order]);


  return (<div>
    <div>
      <ExitModal orderId={order?._id}/>
    </div>
    <div
      className={'card'}
      style={{
        marginLeft: 140,
        marginRight: 140,
        padding: '45px 180px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {order?.['_id'] && <div style={{ fontWeight: 600, marginBottom: 40, color: '#2f65d3' }}>
        Request ID: {order['_id']}
      </div>}
      <Steps current={isLoading ? 0 : status.orderStatus !== 'MAPPING-COMPLETED' ? 2 : 4} direction={'vertical'}>
        <Steps.Step title={<CellDisplay completed={!isLoading} title={'Identifying the source marketplace & template'}
                                        subTitle={'Marketplace & Template'}
                                        value={`${batches?.[0]?.source_marketplace?.name} ${batches?.[0]?.source_OPformat?.article_type}`}/>}/>
        <Steps.Step title={<CellDisplay completed={!isLoading} title={'Identifying the Output template'}
                                        subTitle={'No. of output template'}
                                        value={batches?.length}/>}/>
        <Steps.Step
          title={<MappedFilesDownload completed={status.orderStatus === 'MAPPING-COMPLETED'}
                                      status={status}
                                      fileUrls={fileUrls}
                                      batches={batches ?? []}/>}/>
      </Steps>
      <br/>
      <Button loading={status.orderStatus !== 'MAPPING-COMPLETED'} size={'large'} onClick={() => {
        setQcState(false);
        localStorage.setItem("priceModal", true)
        props.moveToNextStep({})
      }} type={'primary'} style={{ width: 200 }}>
        Start QC
      </Button>
    </div>
  </div>)
}

const CellDisplay = (props) => {
  console.log("CellDisplay", props);
  return <div style={{
    display: 'flex', marginBottom: 20,
  }}>
    <div style={{
      marginRight: 30, color: props.completed ? '#16C612' : '#1f5dd555'
    }}>----- {props.completed ? 'Completed' : 'In Progress'} -----
    </div>
    <div style={{ padding: 20, background: props.completed ? '#16C61255' : '#1f5dd555', borderRadius: 5, width: 300 }}>
      <div>{props.title}</div>
      <div className={'horizontal-line'}/>
      <div className={'sub-title'}>
        {props.subTitle}
      </div>
      <div>{props.value}</div>
    </div>
  </div>
}

const MappedFilesDownload = (props) => {
  console.log("MappedFilesDownload", props);
  const defaultProps = {
    batches: [], fileUrls: [],
  };

  props = { ...defaultProps, ...props };

  return <div style={{
    display: 'flex',
  }}>
    <div style={{
      marginRight: 30, color: props.completed ? '#16C612' : '#1f5dd5'
    }}>----- {props.completed ? 'Completed' : 'In Progress'} -----
    </div>
    <div style={{ padding: 20, background: props.completed ? '#16C61255' : '#1f5dd555', borderRadius: 5, width: 300 }}>
      <div>Mapping</div>
      <div className={'horizontal-line'}/>
      <div className={'sub-title'}>Output Template</div>
      <div style={{
        display: 'flex', flexWrap: 'wrap',
      }}>
        {props.batches.map((d, i) => {
          const stats = props.status?.batchStatuses?.[d._id];
          let progress = 0;
          if (stats) {
            progress = (stats.completed / (stats.progress + stats.pending + stats.completed)) * 100
          }
          return <ProgressiveDownloadButton
            progress={progress}
            nInputs={stats?.completed}
            name={`${d?.destination_OPformat?.article_type}_${d?.destination_OPformat?.category}`}
            url={props.fileUrls[i]}
            callback={async () => await orderService.downloadCallback(d._id)}
          />
        })}
      </div>
    </div>
  </div>
}

const ProgressiveDownloadButton = (props) => {
  //console.log("pr----", props)
  const onClick = () => {
    if (props.progress !== 100) {
      return;
    }
    const link = document.createElement('a');
    link.setAttribute("href", props.url);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    //console.log(props.url)
    link.click();
    props.callback?.();
    document.body.removeChild(link);
  }

  return <div style={{
    margin: 2,
    fontSize: 10,
    background: props.progress === 100 ? 'white' : '#BBB',
    padding: '0px 4px',
    border: '1px solid lightgrey',
    cursor: 'pointer',
  }}
              onClick={onClick}
  >
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}>
      <FileTextOutlined style={{ color: '#16C612', fontSize: 20 }}/>
      &nbsp;
      <div style={{ lineHeight: 1.5, padding: '0 5px' }}>
        {props.name}
        {props.progress === 100 && <div style={{ color: '#16C612', lineHeight: 1.5 }}>
          {props.nInputs} SKU's
        </div>}
      </div>
      &nbsp;-&nbsp;
      {props.progress !== 100 ? `${props.progress}%` : <DownloadOutlined style={{ color: '#16C612', fontSize: 20 }}/>}
    </div>
  </div>
}