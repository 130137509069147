import { axiosConfig } from './config/axios';
import axios from 'axios';

axios.interceptors.request.use(config => {
    let authToken = localStorage.getItem('accessToken');
    let token = authToken ? JSON.parse(authToken).token : '';
    config.headers.common.Authorization = `Bearer ${token}`;
    return config;
})

async function put(url, requestBody) {
    return axios.put(url, requestBody, axiosConfig);
}

async function get(url) {
    return axios.get(url, axiosConfig);
}

async function post(url, body) {
    return axios.post(url, body, axiosConfig);
}

async function postMultiPartFormData(url, formData, uploadProgress) {
    return axios.post(url, formData, {
        ...axiosConfig, headers: {...axiosConfig.headers, 'Content-Type': 'multipart/form-data'}, onUploadProgress: uploadProgress
    })
}

export const httpClient = {
    get, post, postMultiPartFormData, put
}