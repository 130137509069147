import React, { useEffect, useState } from 'react';
import { userService } from '../http/user';
import { useAuth0 } from '@auth0/auth0-react';

export const OrderContext = React.createContext({
  order: {},
  batches: [],
  isLoading: true,

})

export const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState({});
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, isLoading: isLoadingUser } = useAuth0();

  // NOTE: Do not use for anything, this is user by useEffect to manually trigger useEffect callback via refresh() function
  const [change, setChange] = useState(0);

  useEffect(() => {
    if (user) {
      // Fetch orderId from LocalStorage
      console.log(JSON.parse(localStorage.getItem('userFlowObj')))
      const orderId = JSON.parse(localStorage.getItem('userFlowObj'))?.['projectId'];
      console.log("order", orderId);
      if (orderId) {
        userService.userFlow({ subId: user?.sub }).then((flow) => {
          const data = flow.data.message[0];

          if (data) {
            setOrder({ ...data?.user_flow });
            setBatches([ ...data.batches ]);
          }
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }
  }, [isLoadingUser, change])

  const refresh = () => {
    setChange(c => c + 1);
  }

  return (
    <OrderContext.Provider value={{ order, batches, isLoading, setOrder, setBatches, setIsLoading, refresh }}>
      {children}
    </OrderContext.Provider>
  );
}