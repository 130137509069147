import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import { store } from './store'
import { Provider }  from 'react-redux'
import 'antd/dist/antd.min.css';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const client = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUDIENCE

ReactDOM.render(
  <BrowserRouter>
  <Auth0Provider
  domain={domain}
  clientId={client} 
  audience={audience}
  cacheLocation="localstorage"
  redirectUri={window.location.origin}>
    <Provider store={store}>
    <App />
    </Provider>
  </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

