import './AppShell.css';
import '../../css/shared.css';
import Sidebar from '../Sidebar';
import { Link, Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import { Button } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

export function AppShell(props) {

    return (
        <main className="app-shell flex-box">
            <aside className="app-sidebar">
                <Sidebar changeC={props.activeT}/>
            </aside>
            <main className="app-route-outlet">
                <Outlet />
            </main>

          {/*Floating action button*/}
          <div style={{
            position: 'fixed',
            bottom: 80,
            right: 30,
          }} onClick={() => window.location = '/feedback'}>
            <Button
              shape="circle"
              size={'large'}
              type={'primary'}
              icon={<MessageOutlined />}
            />
          </div>
        </main>
    )
}