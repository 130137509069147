import './MultiStepProgress.css';
import '../../css/shared.css';
import { MultiProgressHeader } from '../MultiStepProgressHeader/MultiStepProgressHeader';

export function MultiStepProgress(props) {
    let { menu, currentStep } = props;
    return (
        <section className="multi-step-progress">
            <section className="multi-step-progress__header-container">
                <MultiProgressHeader currentStep={currentStep} menus={menu} />
            </section>
            <section className="multi-step-progress__container">
                {props.children[currentStep]}
            </section>
        </section>
    )
}