import { PanelHeader } from './PanelHeader/PanelHeader';
import { PanelFooter } from './PanelFooter/PanelFooter';
import { PanelContent } from './PanelContent/PanelContent';
import './RightPanel.css';
import { useEffect, useState } from 'react';

export function RightPanel(props) {
  const [skuJSON, setSkuJSON] = useState({});
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  
  useEffect(() => {
    setAttemptedSubmit(false);
  }, [props.items.mappedData])

  return (
    <div className="right-panel">
      <PanelHeader batchCounts={props.batchCounts} activeBatchIndex={props.activeBatchIndex} completedCount={props.completedCount} totalCount={props.totalCount} loadSku={props.loadSku} trueTotalCount={props.trueTotalCount}/>
      <PanelContent items={props.items} skuJson={skuJSON} setSkuJSON={setSkuJSON} attemptedSubmit={attemptedSubmit}/>
      <PanelFooter skuId={props.skuId} completedCount={props.completedCount} totalCount={props.totalCount} qcData={skuJSON} outputFormat={props.items.inputData} loadSku={props.loadSku}
                   attemptSubmit={() => setAttemptedSubmit(true)}/>
    </div>
  )
}