import './PanelHeader.css';
import ProgressIcon from '../../../../static/images/progress-icon.png';
import { Progress, Segmented, Space, Tooltip } from 'antd';
import { useRecoilState } from 'recoil';
import { qcFilterState } from '../../../../atoms/qc.atom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useOrder } from '../../../../hooks/order.hook';

export function PanelHeader(props) {
  const [qcFilter, setQcFilter] = useRecoilState(qcFilterState);
  const { order } = useOrder();

  return (
    <>
      <div className={'panel-segmented'}>
        <Segmented
          options={[
            {
              value: false,
              label: 'All Units',
            },
            {
              value: true,
              label: 'Filtered Units',
              icon: <Tooltip
                title="Enabling this filter will require you to QC only units where one/more mandatory attributes are not filled yet. You can skip QC of all units that have all mandatory attributes filled.">
                <InfoCircleOutlined style={{ transform: 'translateY(-4px)' }}/>
              </Tooltip>,
            },
          ]}
          size={'large'}
          value={qcFilter}
          onChange={(v) => {
            setQcFilter(v);
            props.loadSku();
          }}
        />
      </div>
      <div className="panel-header">
        <div className='template-details'>
          <p>{props.totalCount} {qcFilter && 'Filtered'} Units</p>
          <p>{props.batchCounts[props.activeBatchIndex]?.['destination_article_type']} {qcFilter && <>({props.trueTotalCount} units)</>}</p>
        </div>
        <div className='template-progress'>
          <p style={{ color: '#fff' }}><img className='progress-icon' src={ProgressIcon}
                                            alt="Icon"/> {props.completedCount} of {props.totalCount} Units Completed
          </p>
          <Progress percent={props.completedCount * 100 / props.totalCount} strokeColor={'lime'} showInfo={false}/>
        </div>
        {order?.['_id'] && <div style={{ fontWeight: 600, color: 'white', margin: 'auto 0', paddingLeft: 20 }}>
          Request ID: {order['_id']}
        </div>}
      </div>
    </>
  )
}