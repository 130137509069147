import { Header } from './Header/Header';
import { LeftPanel } from './LeftPanel/LeftPanel';
import { RightPanel } from './RightPanel/RightPanel';
import './EditorDashboard.css';
import { useEffect, useState } from 'react';
import { useOrder } from '../../hooks/order.hook';
import { orderService } from '../../http/order';
import { Spin, Modal, notification } from 'antd';
import * as _ from 'lodash';
import { useRecoilState } from 'recoil';
import { qcFilterState } from '../../atoms/qc.atom';
import PriceModal from '../PriceModal/PriceModal';

export function EditorDashboard(props) {
  const { order } = useOrder();
  const [activeBatchIndex, setActiveBatchIndex] = useState(0);
  const [batchCounts, setBatchCounts] = useState([]);
  const [outputFormat, setOutputFormat] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [mappedData, setMappedData] = useState({});
  const [imageCollection, setImageCollection] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [eagerlyCompletedCount, setEagerlyCompletedCount] = useState(0);
  const [skuId, setSkuId] = useState();
  const [isLoadingSku, setIsLoadingSku] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const [qcFilter, setQcState] = useRecoilState(qcFilterState);

  useEffect(() => {
    const oldobj = JSON.parse(localStorage.getItem("userFlowObj"));
    const userFlowObj = {
      ...oldobj,
      flow_index: 2
    };
    localStorage.setItem("userFlowObj", JSON.stringify(userFlowObj));
  }, [])

  useEffect(() => {
    setIsLoadingSku(true);
    //console.log('id: ', order?._id);
    orderService.getBatchCounts(order?._id).then(async ({ data }) => {
      setBatchCounts(data);
      let sku;
      if (qcFilter) {
        const { data: d } = await orderService.fetchFilteredQcSku(data[activeBatchIndex]?.batch_id);
        sku = d;
      } else {
        const { data: d } = await orderService.fetchSKU(data[activeBatchIndex]?.batch_id);
        sku = d;
      }
      if (sku.templateData == null) {
        if (qcFilter) {
          console.log('Completing remaining skus for filtered qc', activeBatchIndex , data[activeBatchIndex])
          await orderService.passQCForFulfilledInputs(data[activeBatchIndex]?.batch_id);
        }
        if (activeBatchIndex >= data.length || activeBatchIndex === -1) {
          props.moveToNextStep();
          return;
        } else {
          setActiveBatchIndex(i => {
            return _.findIndex(data, (o) => o.status !== 'QC-COMPLETED');
          });
          loadSku();
        }
      }

      // if (eagerlyCompletedCount != null && sku.eagerlyCompleted != null && eagerlyCompletedCount < sku.eagerlyCompleted) {
      //   console.log(eagerlyCompletedCount, sku.eagerlyCompleted)
      //   Notification('warn', 'Some SKUs autocompleted', `${sku.eagerlyCompleted - eagerlyCompletedCount} SKUs were automatically completed`);
      //   // notification.warn(`${sku.eagerlyCompleted - eagerlyCompletedCount} SKUs were automatically completed`);
      // }
      console.log('CURRENT SKU', sku)
      setSkuId(sku.templateData?._id);
      setOriginalData(sku?.templateData?.data);
      setMappedData(sku?.templateData?.mapped_data);
      setOutputFormat(sku.outputFormat);
      setTotalCount(sku.total);
      setCompletedCount(sku.completed);
      setEagerlyCompletedCount(sku.eagerlyCompleted);
      setImageCollection(filterImagesFromJSON(sku?.templateData?.data));
      setIsLoadingSku(false);
    })
  }, [order, refreshKey]);

  const loadSku = () => {
    setRefreshKey((i) => i + 1);
  }

  const filterImagesFromJSON = (json) => {
    if (!json) return;
    const imageUrls = [];
    Object.keys(json).forEach((key) => {
      //console.log(json, key, json[key])
      const keyName = (json[key]).toString();
      if ((keyName?.startsWith('https://')) || (keyName?.startsWith('http://'))) {
        imageUrls.push({ thumbnail: json[key], original: json[key] });
      }
    });
    return imageUrls;
  }

  const items = {};
  return (
    <Spin tip="Loading..." spinning={isLoadingSku}>
      <div className='editorDashboard'>
        <PriceModal orderId={order?._id} loadSku={loadSku}/>
        <Header currentBatch={activeBatchIndex} batchCounts={batchCounts} orderId={order?._id}/>
        <div className="panels">
          <LeftPanel images={imageCollection}/>
          <RightPanel
            skuId={skuId}
            items={{ mappedData, inputData: outputFormat }}
            completedCount={completedCount + eagerlyCompletedCount}
            activeBatchIndex={activeBatchIndex}
            batchCounts={batchCounts}
            totalCount={totalCount}
            trueTotalCount={batchCounts[activeBatchIndex]?.total}
            loadSku={loadSku}/>
        </div>
      </div>
    </Spin>
  )
}

