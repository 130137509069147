import React, { useState } from 'react';
import { Button, Form, Input, Select, notification } from 'antd';
import { storeService} from '../../http/storeConfig';

const { Option } = Select;

const StorageDashboard = () => {

  const [templateInfo, setTemplateInfo] = useState({});
  const [responded, setMessage] = useState(false);
  const [loading, setLoading] = useState();
  const fileList = ['sourceFile', 'destinationFile', 'mappingFile'];

  const onFinish = async (values) => {
    
    const formData = new FormData();
    Object.keys(values).forEach(element => {
        if (!fileList.includes(element)){
          const val = values[element].replace("C:\\fakepath\\", "")
          formData.append(element, val)
        }
        else{
          console.log("Other keys in values");
        }
    });
    
    for(const c in templateInfo){
      formData.append('files', templateInfo[c])
    }
    const result = await storeService.storageTemplate(formData);
    console.log('result', result.data);
    result.data.data ? setMessage(result.data): setMessage(result);
    setLoading(false);
    notification.open({
      message: "Storage Status",
      description:
      result.data.success != true ? 
      `Error Occured while storing the templates, please check the mapping file allowed values or
       the meesho input file and Flipkart AT's You have entered if you are unable figure out the problem please 
       reach out to Cataloging Tech Team` : `Template stored Successfully`, 

       duration: 15,
    });
  };

  
  const onFileUpload = async (event) => {
    
    const {name, files} = event.target
    setTemplateInfo(pre => {
      if (name === 'sfile'){
      return {...pre, sfile:files[0]}
      }

      else if (name === 'dfile'){
        return {...pre, dfile:files[0]}
      }

      else if (name === 'mfile'){
        return {...pre, mfile:files[0]}
      }
      else {
        console.log("Wrong file upload");
      }
    })

  }

  const enterLoading = () => {
    setLoading(true);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (<div className='containerForm'>
    <h1 style={{marginLeft: '40%'}}>Template Storage</h1>
    <Form
      name="basic"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    > 

    <Form.Item
        label="Source File Path"
        name="spaths"
        rules={[
          {
            required: true,
            message: 'Please file source path!',
          },
        ]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Source File"
        name="sourceFile"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type='file' name='sfile' onChange={onFileUpload}/>
      </Form.Item>

      <Form.Item
        label="Destination File Path"
        name="dpaths"
        rules={[
          {
            required: true,
            message: 'Please destination file path!',
          },
        ]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Destination File"
        name="destinationFile"
        rules={[
          {
            required: true,
          },
        ]}
      > 
        <Input type='file' name='dfile' onChange={onFileUpload}/>
      </Form.Item>

      <Form.Item
        label="Mapping File"
        name="mappingFile"
        rules={[
          {
            required: true,
            message: 'Please mapping file path!',
          },
        ]}
      >
        <Input type='file' name='mfile' onChange={onFileUpload}/>
      </Form.Item>

      <Form.Item
        label="Token"
        name="token"
        rules={[
          {
            required: true,
            message: 'Please enter token',
          },
        ]}
      >
        <Input/>
      </Form.Item>

      {/* <Form.Item name="mappingType" label="Select Mapping Type" rules={[{ required: true }]}>
        <Select
          placeholder="Select a option and change input text above"
          allowClear
        >
          <Option value="One to One">One to One</Option>
          <Option value="One to Many">One to Many</Option>
        </Select>
      </Form.Item> */}

      <Form.Item
        wrapperCol={{
          offset: 6,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading} onClick={enterLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
};
export default StorageDashboard;