import React, { useState, useEffect } from "react";
import "./templateView.css";
import "../../css/Dashboard.css";
import { Button, Input, Select } from "antd";
import { SearchOutlined, SyncOutlined} from '@ant-design/icons';
import { orderService } from '../../http/order';
import { allTemplateService } from '../../http/allTemplateView';
import Tb from './Tb'
import Tbconfigured from "./Tbdconfigured";
import ExcelUpload from "./ExcelUpload";
import * as xlsx from 'xlsx';
import DownloadBtn from '../../static/images/Download.svg';
import AddBtn from '../../static/images/Add button.svg';
import BackBtn from '../../static/images/Back button.svg';
import DropArrow from '../../static/images/Dropdown icon.svg'

// const data = [
//   {
//     key: 1,
//     sno: 1,
//     category: "Clothing",
//     template: "Kurta",
//     splitAttriuteValue: "Type",
//     missedValue1: "Baby Boy",
//     missedValue2: "Baby Boys & Baby Girls",
//     missedValue3: "Boys",
//     missedValue4: "Men & Women",
//     missedValue5: "Men"
//   }  
// ];


let val = [];
let allValue = [];
let category = {
  all: {
    source:[],
    destination:[]
  },
  un:[]
}

let btnActive = {
  btnAll:false,
  btnUn:false
}
const TemplateTable = () => {

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [tdata, setTdata] = useState(true);
  const [downloadData, setDownloadData] = useState();
  const [filterCount, setFilterCount] = useState({
    btnAll:0,
    btnUn:0
  });
  const [tempButton, setTempButton] = useState(true);
  const [inputMarket, setInputMarket] = useState("");
  const [outputMarket, setOutputMarket] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState();
  const [btnOne, setBtnOne] = useState('All Templates');
  const [checkCount, setCheckCount] = useState({
    btnAll:true,
    btnUn:true
  });
  const { Option } = Select;

  useEffect(() => {
  
    if(!tempButton){
      // setCheckCount({
      //   btnAll:true,
      //   btnUn:true
      // })
      fetchAllTemplate()
    }

  },[isFileSelected, inputMarket, outputMarket])

  useEffect(() => {
    
    if(!tempButton){
      setCheckCount({
        btnAll:true,
        btnUn:true
      })
    }

  },[tdata])



  const onFileChange = async (event) => {

    if (event) {
      console.log("if",event.target.files[0]);
      const fileName = event.target.files[0];
      if (allowedExtensions.includes(findIsValidFileFormat(fileName.name))){
        setSelectedFile(fileName);
        setIsFileSelected(true)
        setBtnOne('Configured Templates')
      }
      else {
        console.log("Wrong file Uploaded");
      }
      
    }
    else {
      setSelectedFile(null);
      setIsFileSelected(false);
      setBtnOne('All Templates');
    }
  }

  const findIsValidFileFormat = (fileName) => {
    const fileparts = fileName.split('.');
    const extension = fileparts[fileparts.length - 1];
    return extension
  }

  const fetchData = async () => {
    setLoading(true)
    // setBtnActive({
    //   btnAll:false,
    //   btnUn:true
    // })
    btnActive = {
      btnAll:false,
      btnUn:true
    }
    setTdata(false);
    const formData = new FormData();
    if (isFileSelected) {
      console.log("checking");
      formData.append('file', selectedFile)
    } else {
      console.log("in else");
    }
    const unData = await orderService.fetchTemplate(inputMarket, outputMarket,formData)
    // console.log("ss", selectedFile);
    // console.log('uncdada', unData);
    const tableData = [];
    let unCategory = [];
    console.log("uuuuuuuuuuuuu", unData['data'])
    unData['data']['info'].sort(function (a, b){
        return a.template?.localeCompare(b.template) 
      })
    unData['data']['info'].forEach((element, index) => {
      // console.log(element, index);
      unCategory.push(element['category']);
      const count = index + 1;
      let tableRow = {};
      tableRow['key'] = count;
      tableRow['sno'] = count;
      tableRow['Category'] = element['category'];
      tableRow['template'] = element['template'];
      tableRow['Split Attribute'] = element['splitAttribute'];
      element['missedValues'].forEach((missV, index) => {
        tableRow[`Missed Value${index + 1}`] = missV
      });
      tableData.push(tableRow)
    });
    category['un'] = [...new Set(unCategory)].sort();
    val = tableData;

    console.log("checkcount out ", checkCount, btnActive);

    if(btnActive['btnUn'] && !checkCount['btnUn'] && !checkCount['btnAll']){
      setTotalCount(filterCount['btnUn'])
    }
    else if (btnActive['btnUn'] && checkCount['btnUn']){
      console.log("checkcount ", checkCount);
      setTotalCount(tableData.length);
    }
    setRows(tableData);
    setDownloadData(tableData);
    setRefresh(false)
    setLoading(false);
  }

  const fetchAllTemplate = async () => {
    setLoading(true);
    // setBtnActive({
    //   btnAll:true,
    //   btnUn:false
    // })
    btnActive = {
      btnAll:true,
      btnUn:false
    }
    let unCategory = {};
    unCategory[inputMarket] = [];
    unCategory[outputMarket] = [];
    // console.log("hhhhhh", isFileSelected);
    if (isFileSelected) {
      setTdata(true);
      const formData = new FormData();
      formData.set('file', selectedFile);
      console.log('formdata', formData);
      const data = await allTemplateService.fetchTemplateDataUsingAt(formData, inputMarket, outputMarket);
      console.log('data using at', data);
      const tableData = [];
      data['data']['data'].forEach((ele, index) => {
        // console.log('data in dadhfjdhfkjd')
        const count = index + 1;
        let tableRow = {};
        // console.log('date', ele);
        if (ele['D_one_many_data'] != null) {
          // console.log('null', ele);
          tableRow['Stored On'] = ele['D_one_many_data']['created_on'].split('T')[0] ?? '-';
          tableRow['DAT'] = ele['D_one_many_data']['article_type'] ?? '-';
          tableRow['DCat'] = ele['D_one_many_data']['category'] ? ele['D_one_many_data']['category'] : '-';
        }
        else if (ele['D_one_one_data'] != null) {
          tableRow['Stored On'] = ele['D_one_one_data']['created_on'].split('T')[0] ?? '-';
          tableRow['DAT'] = ele['D_one_one_data']['article_type'] ? ele['D_one_one_data']['article_type'] : '-';
          tableRow['DCat'] = ele['D_one_one_data']['category'] ? ele['D_one_one_data']['category'] : '-';
          unCategory[outputMarket].push(ele['D_one_one_data']['category']);
        }
        tableRow['SCat'] = ele['category'];
        unCategory[inputMarket].push(ele['category']);
        tableRow['SAT'] = ele['article_type'];
        tableRow['Split Attribute'] = ele['splitAttribute'] ? ele['splitAttribute'] : '-';
        tableRow['Split Attribute Value'] = ele['splitValue'] ? ele['splitValue'] : '-';

        tableData.push(tableRow);
        // console.log('table 111', tableData);
      });
      console.log('tabledata', tableData);
      allValue = tableData;
      category['all']['source'] = [...new Set(unCategory[inputMarket])];
      category['all']['destination'] = [...new Set(unCategory[outputMarket])];

      tableData.sort(function (a, b){
        return new Date(b['Stored On']) - new Date(a['Stored On'])
      })
      
      tableData.forEach((ele, index) => {
        ele['key'] = index +1;
        ele['sno'] = index + 1;
      })
      // const allCa = [...new Set(unCategory)];
      console.log("checkcount ", checkCount, btnActive);
      if(btnActive['btnAll'] && !checkCount['btnAll'] && !checkCount['btnUn']){
        setTotalCount(filterCount['btnAll'])
      }
      else if (btnActive['btnAll'] && checkCount['btnAll']){
        console.log("checkcount ", checkCount);
        setTotalCount(tableData.length);
      }
      // setTotalCount(tableData.length);
      setRows(tableData);
      setDownloadData(tableData);
      setRefresh(false);
      setLoading(false);
    }
    else {
      setTdata(true)
      setLoading(true);
      // console.log("market", inputMarket, outputMarket);
      const data = await allTemplateService.fetchAllTemplateData(inputMarket, outputMarket);
      const tableData = [];
      data['data']['data']['main_data'].sort(function (a, b){
        return new Date(b.destination_createdAt) - new Date(a.destination_createdAt)
      })
      data['data']['data']['main_data'].forEach((ele, index) => {
        const count = index + 1;
        let tableRow = {};
        tableRow['key'] = count;
        tableRow['sno'] = count;
        tableRow['Stored On'] = ele['destination_createdAt'].split('T')[0];
        tableRow['SCat'] = ele['source_cat'];
        tableRow['SAT'] = ele['sourceAT'];
        tableRow['DAT'] = ele['destination_AT'];
        tableRow['DCat'] = ele['destination_cat'];
        tableRow['Split Attribute'] = ele['split_attribute'] ? ele['split_attribute'] : '-';
        tableRow['Split Attribute Value'] = ele['split_value'] ? ele['split_value'] : '-';
        unCategory[inputMarket].push(ele['source_cat']);
        unCategory[outputMarket].push(ele['destination_cat']);
        tableData.push(tableRow);
      });
      allValue = tableData;
      category['all']['source'] = [...new Set(unCategory[inputMarket])].sort();
      category['all']['destination'] = [...new Set(unCategory[outputMarket])].sort();
      console.log("checkcount ", checkCount, btnActive);
      if(btnActive['btnAll'] && !checkCount['btnAll'] && !checkCount['btnUn']){
        setTotalCount(filterCount['btnAll'])
      }
      else if (btnActive['btnAll'] && checkCount['btnAll']){
        console.log("checkcount ", checkCount);
        setTotalCount(tableData.length);
      }
      // setTotalCount(tableData.length);
      // console.log("type",typeof(tableData));
      setRows(tableData);
      setDownloadData(tableData);
      setRefresh(false);
      setLoading(false);
    }
  }

  const download = async (data) => {
    const book = xlsx.utils.book_new();
    let downData = JSON.parse(JSON.stringify(data))
    console.log(downData);
    let result = []
    if(btnActive['btnAll']){
      downData.forEach((ele, index) => {
        let obj = {}
        obj['S.No'] = ele['sno'];
        obj["Stored On"] = ele["Stored On"];
        obj[`${inputMarket} Category`] = ele['SCat'];
        obj[`${inputMarket} Template`] = ele['SAT'];
        obj[`${outputMarket} Category`] = ele['DCat'];
        obj[`${outputMarket} Template`] = ele['DAT'];
        obj['Split Attribute'] = ele['Split Attribute'];
        obj['Split Attribute Value'] = ele['Split Attribute Value']

        result.push(obj)
      })
    }
    else {
      downData.forEach((ele,index) => {
        let obj = {}
        obj['S.No'] = ele['sno'];
        obj[`${inputMarket} Category`] = ele['Category'];
        obj[`${inputMarket} Template`] = ele['template'];
        const eleKey = Object.keys(ele);
        const sliced = eleKey.slice(5, eleKey.length).reduce((result, key) => {
            result[key] = ele[key];
            return result;
        }, {});
        result.push({...obj, ...sliced})
        
      })
    }
    
    const ws = xlsx.utils.json_to_sheet(result);
    book.SheetNames.push('data');
    book.Sheets['data'] = ws;
    const date = new Date();
    btnActive['btnAll'] ? xlsx.writeFile(book, `${btnOne}-${date}./.xlsx`): xlsx.writeFile(book, `Uncongifured_Templates-${date}./.xlsx`)
  }

  const filterTable = (event) => {
    const currValue = event.target.value;
    let filteredData = [];
    if (tdata) {
      filteredData = allValue.filter(entry =>
        entry.SAT.includes(currValue)
      );
    }
    else {
      filteredData = val.filter(entry =>
        entry.template.includes(currValue)
      );
    }

    // console.log(filteredData);
    setRows(filteredData)

  }

  const getInputName = (value) => {
    console.log(value);
    setInputMarket(value)
  }

  const getOutputName = (value) => {
    console.log(value);
    setOutputMarket(value);
    setTempButton(false)
    fetchAllTemplate();
  }

  const removeFile = async () => {
    // console.log('eee', e);
    await onFileChange();
  }

  const allowDrop = (ev) => {
    ev.preventDefault();
  }

  const drop = (ev) => {
    ev.preventDefault();
    if(selectedFile == null){
      setSelectedFile(ev.dataTransfer.files[0]);
      setIsFileSelected(true);
    }
  }


  const refreshContent = async() => {
    if (btnActive['btnAll']){
      setRefresh(true)
      await fetchAllTemplate();
    }
    else if (btnActive['btnUn']){
      setRefresh(true)
      await fetchData()
    }
    else{
      console.log("check refresh button value");
    }
  }

  const allowedExtensions = ['xlsx', 'xls', 'xlsb'];

  return (<>

    <div className='flex-container'>
      <div className='flex-child'>
        <span className="input" style={{fontWeight:"normal"}}>Input Marketplace : &nbsp;</span><Select className="marketPlace" name="input" onChange={getInputName} suffixIcon=<img src={DropArrow}/>>
          <Option key="Flipkart" value='Flipkart'></Option>
          {/* <Option key="Meesho" value='Meesho'></Option> */}
          {/* <Option key="Nykaa" value='Nykaa'></Option> */}
          {/* <Option key="Myntra" value='Myntra'></Option> */}
        </Select>
        <div style={{ height: "90%" }}><img onClick={removeFile} src={BackBtn} style={{ display: isFileSelected ? 'inline' : 'none', marginRight:"1.5%"}}/>
          <Input className='label_search'
            type="search"
            placeholder={`Search ${inputMarket} Template`}
            prefix={<SearchOutlined style={{color:"#C4C4C4"}}/>}
            onChange={filterTable}
          ></Input></div>
      </div>
      <div className='flex-child'>
        <span className="input" style={{fontWeight:"normal"}}>Output Marketplace : &nbsp;</span><Select className="marketPlace" onChange={getOutputName} style={{fontWeight:"bold"}} suffixIcon=<img src={DropArrow}/>>setIsFileSelected(false)
          {/* <Option value='Flipkart'></Option> */}
          <Option key='Meesho' value='Meesho'></Option>
          {/* <Option value='Nykaa'></Option> */}
          {/* <Option value='Myntra'></Option> */}
        </Select>
        <h6 style={{marginTop:"2%", color:"#848484", fontSize:"12px"}}>Bulk Template Check</h6>
        {!isFileSelected ? <section className='addBulk' onDrop={drop} onDragOver={allowDrop} onDragEnter={allowDrop}><label className="add"><img src={AddBtn}/><input style={{ width: "40%", display:"none" }} id="addFile" type='file' onChange={onFileChange} />
        <span className="addPlaceholder" htmlFor="addFile">Or Drag and drop file here</span></label></section>:
        <ExcelUpload file={selectedFile} removeFile={removeFile}/>}
      </div>
    </div>

    <div className="level-3">
      <div className="templateContainer">
        <Button className="temp-btn" type='primary' size="large" onClick={fetchAllTemplate} style={{backgroundColor: btnActive['btnAll']?"#2856AF":"#FDFDFD", color: btnActive['btnAll']?"#FFFFFF":"#E3E3E3"}} disabled={tempButton}>{btnOne}
        </Button>
        <Button className="temp-btn" type="primary" size="large" onClick={fetchData} style={{ marginRight: "10px",backgroundColor: btnActive['btnUn']?"#2856AF":"#FDFDFD", color: btnActive['btnUn']?"#FFFFFF":"#E3E3E3"}} disabled={tempButton}>Unconfigured Templates
        </Button>
      </div>
      <div style={{paddingTop:"6px", marginLeft:"10px"}}>
        <SyncOutlined spin={refresh} style={{color:"#263159"}} onClick={refreshContent}></SyncOutlined>
      </div>
      <div className="inputFilter">
        <div className="totalCount"><span>Total Count : {totalCount}</span></div>

        <img onClick={() => download(downloadData)} src={DownloadBtn} style={{margin:"0 0 1% 3%"}}/>  
      </div>
    </div>
    {tdata ? <Tbconfigured tbD={rows} allCat={category['all']} load={loading} inputMarket={inputMarket} outputMarket={outputMarket} count={setTotalCount} filterDownload={setDownloadData} checkCt={setCheckCount}/> : <Tb tbD={rows} unCat={category['un']} load={loading} inputMarket={inputMarket} outputMarket={outputMarket} count={setTotalCount} filterDownload={setDownloadData} checkCt={setCheckCount}/>}
    {/* <Tbconfigured tbD={rows} allCat={category['all']} load={loading} inputMarket={inputMarket} outputMarket={outputMarket} count={setTotalCount} filterDownload={setDownloadData} checkCt={setCheckCount} filterCount={setFilterCount} style={{opacity: tdata ? 1: 0, height:tdata? 'unset':0}}/>
    <Tb tbD={rows} unCat={category['un']} load={loading} inputMarket={inputMarket} outputMarket={outputMarket} count={setTotalCount} filterDownload={setDownloadData} checkCt={setCheckCount} filterCount={setFilterCount} style={{opacity: tdata ? 0: 1, height:tdata? 0:'unset', overflow: tdata? 'hidden': null}}/> */}
  </>

  )
};

export default TemplateTable;
