import React, { useState } from "react";
import "./templateView.css";
import { Table } from "antd";
import ShowConfirm from "./ShowConfirm";
import FilterSVG from "../../static/images/Filter icon.svg";
const Tb = (props) => {

  const columnsUnconfigured = [
    {
      title: "SL.No",
      dataIndex: "sno",
      key: "sno",
      fixed: 'left',
      width: "4%"
    },
    {
      title: `${props.inputMarket} Category`,
      dataIndex: "Category",
      key: "category",
      fixed: 'left',
      width: "13%",
      filters: props.unCat.map(ele => {
          return {
              text: ele,
              value: ele,
            }
      }),
      filterIcon: <img src={FilterSVG}/>,
      onFilter: (value, record) => record.Category?.indexOf(value) === 0,
      className: "filterStyle"
    },
    {
      title: `${props.inputMarket} Template`,
      dataIndex: "template",
      key: "template",
      fixed: 'left',
      width: "12%",
      sorter: (a, b) => a.template?.localeCompare(b.template),
      className: "sorterStyle",
      render: (text, record) => {
        if (record.Category === 'NA' && record['Split Attribute'] === 'NA'){
            return <ShowConfirm check={record} sourceM={props.inputMarket} destinationM={props.outputMarket}>{text}</ShowConfirm>
        }
        return text
      },
    },
    {
      title: "Split Attribute",
      dataIndex: "Split Attribute",
      key: "sno",
      fixed: 'left',
      width: "12%",
      className: "borderCol"
    },
  ]

  for (const i of Array(56).keys()) {
    const count = i + 1;
    columnsUnconfigured.push({
      title: `Missed Value ${count}`,
      dataIndex: `Missed Value${count}`,
      key: `missedValue${count}`,
      width: "9%",
      render: (text, record) => {
        return <ShowConfirm check={record} sourceM={props.inputMarket} destinationM={props.outputMarket}>{text}</ShowConfirm>
      }

    })
  }

  const handleChange = (pagination, filters, sorter, currentDataSource) => {
    console.log('Various parameters', filters, currentDataSource, typeof(currentDataSource), currentDataSource.currentDataSource.length);
    if (filters['category'] === null){
      props.checkCt(pre => {
        return {...pre, btnUn:true}
      });
    }
    else{
      props.checkCt(pre => {
        return {...pre, btnUn:false}
      });
    }
    // props.filterCount(pre => {
    //   return {...pre, btnUn:currentDataSource.currentDataSource.length}
    // })
    props.count(currentDataSource.currentDataSource.length)
    props.filterDownload(currentDataSource.currentDataSource)
  };

  return (<>
    <Table
      style={{ marginRight: "7%",
      'borderTopLeftRadius': '5px',
      'borderBottomLeftRadius': '5px',  'borderTopRightRadius': '5px',
      'borderBottomRightRadius': '5px', 
      marginRight:"7%", border:"1px solid rgba(0, 0, 0, 0.05)",
      ...props.style}}
      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
      columns={columnsUnconfigured}
      dataSource={props.tbD}
      loading={props.load}
      size="middle"
      scroll={{
        x: 8000,
        y: 700,
      }}
      pagination={{defaultPageSize:50}}
      onChange={handleChange}
    />

    
  </>)
}

export default Tb