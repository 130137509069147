import { Gallery } from './Gallery/Gallery';
import './LeftPanel.css';

export function LeftPanel(images) {
  return (
    <div className="left-panel">
      <div className="tabs">
        <button className='tab selected'>Images</button>
        <button className='tab'>Attributes</button>
        <button className='tab'>Info</button>
      </div>
      <div className='tab-content'>
        <Gallery images={images.images ?? []} />
      </div>
    </div>
  )
}