import React, { useState, useRef } from "react";
import "./templateView.css";
import { Table, Button, Modal, Input } from "antd";
import { orderService } from '../../http/order';
import FilterSVG from "../../static/images/Filter icon.svg";
import { Notification } from '../Notifications/Notification'

const { TextArea } = Input;

const Tbconfigured = (props) =>{

    const inner = useRef("")
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [content, setContent] = useState("");
    const [atName, setAtname] = useState({
        inputAT: "",
        outputAT: "",
        splitAttribute: "",
        splitAttributeValue: ""
    })

    const showModal = (info, data) => {
        console.log("info", info, data);
        setAtname({inputAT: data.SAT, outputAT: data.DAT, splitAttribute: data['Split Attribute'], splitAttributeValue: data['Split Attribute Value']});
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        console.log("props", content, inner.current.resizableTextArea.props.value,inner);
        const report_data = {
            Report: `${content}`,
            sourceclient: props.inputMarket,
            destinationclient : props.outputMarket,
            sourcetemplate : atName['inputAT'],
            destinationtemplate: atName['outputAT'],
            splitAttribute: atName['splitAttribute'],
            splitAttributeValue: atName['splitAttributeValue']
        }
        console.log("rrrrr", report_data);
        const res = await orderService.report(report_data);
        if(res.data.message === "Done"){
            Notification('success', `${atName['inputAT']}` ,`Your report for ${atName['inputAT']} has been logged. We will get back to you shortly on this`)
          }
          else {
            console.log("some issue", res)
          }
        document.getElementsByClassName('anticon anticon-close-circle ant-input-clear-icon')[0].click();
        setContent("")
        setIsModalVisible(false);

    };

    const handleCancel = () => {
    setIsModalVisible(false);
    };

    const onChangeTable = (event) => {
        // console.log('Various parameters', event.target.value);
        const data = event.target.value;
        // setContent(pre => {
        //     return [...pre,data]
        // })
        setContent(data)
      };

    const columnsconfigured = [
        {
        title: "Sl.No",
        dataIndex: "sno",
        key: "sno",
        width: "1.5%",
        },
        {
        title: "Stored On",
        dataIndex: "Stored On",
        key: "date",
        width: "3%",
        sorter: (a, b) => a['Stored On']?.localeCompare(b['Stored On']),
        className: "sorterStyle"
        },
        {
        title: `${props.inputMarket} Category`,
        dataIndex: "SCat",
        key: "s_cat",
        width: "4%",
        filters: props.allCat['source'].map(ele => {
            return {
                text: ele,
                value: ele,
              }
        }),
        filterIcon: <img src={FilterSVG}/>,
        onFilter: (value, record) => record.SCat?.indexOf(value) === 0,
        sorter: (a, b) => a.SCat?.localeCompare(b.SCat),
        className: "filterStyle"
        },
        {
        title: `${props.inputMarket} Template`,
        dataIndex: "SAT",
        key: "s_at",
        width: "4%",
        sorter: (a, b) => a.SAT?.localeCompare(b.SAT),
        className: "sorterStyle",
        },
        {
        title: `${props.outputMarket} Template`,
        dataIndex: "DAT",
        key: "dat",
        width: "4%",
        sorter: (a, b) => a.DAT?.localeCompare(b.DAT),
        className: "sorterStyle",
        },
        {
        title: `${props.outputMarket} Category`,
        dataIndex: "DCat",
        key: "dcat",
        width: "4%",
        filters: props.allCat['destination'].map(ele => {
            return {
                text: ele,
                value: ele,
              }
        }),
        filterIcon: <img src={FilterSVG}/>,
        onFilter: (value, record) => record.DCat.indexOf(value) === 0,
        sorter: (a, b) => a.DCat?.localeCompare(b.DCat),
        className: "filterStyle"
        },
        {
        title: "Split Attribute",
        dataIndex: "Split Attribute",
        key: "splitat",
        width: "3%",
        },

        {
        title: "Split Attribute Value",
        dataIndex: "Split Attribute Value",
        key: "splitval",
        width: "3%"
        },
        {
        title: "",
        dataIndex: "report",
        key: "report",
        width:"2%", 
        render: (text, record) =>( <Button type="primary" style={{background:"#2856AF"}} 
            onClick={() => showModal(text, record)}>
            Report 
        </Button>)
        }
    ]

    const handleChange = (pagination, filters, sorter, currentDataSource) => {
        console.log('Various parameters', filters, currentDataSource, typeof(currentDataSource), currentDataSource.currentDataSource.length);
        if (filters['dcat'] === null && filters['scat'] === null){
            // props.checkCt(true);
            props.checkCt(pre => {
                return {...pre, btnAll:true}
              });
        }
        else{
            props.checkCt(pre => {
                return {...pre, btnAll:false}
              });
        }
        // props.filterCount(pre => {
        //     return {...pre, btnAll:currentDataSource.currentDataSource.length}
        //   })
        props.count(currentDataSource.currentDataSource.length);
        props.filterDownload(currentDataSource.currentDataSource)
      };


    return (<>
        <Table
            style={{ 'borderTopLeftRadius': '5px',
            'borderBottomLeftRadius': '5px',  'borderTopRightRadius': '5px',
            'borderBottomRightRadius': '5px', 
             marginRight:"7%", border:"1px solid rgba(0, 0, 0, 0.05)",
             ...props.style}}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark' }
            columns={columnsconfigured}
            dataSource={props.tbD} 
            loading= {props.load}
            size="middle"
            scroll={{
            // x: 1500,
            y: 700,
            }}
            pagination={{defaultPageSize:50}}
            onChange={handleChange}
        >
        </Table>

        <Modal 
        // key={1}
        className="rowClassName1 reportClass"
        title={[
            <span>
            <p>Report Issue</p>
            <p>Flipkart {atName['inputAT']} To Meesho {atName['outputAT']}
            </p>
            </span>
        ]}
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        okText='Report' 
        cancelText='Cancel'
        style={{ 'borderTopLeftRadius': '7px',
        'borderBottomLeftRadius': '7px',  'borderTopRightRadius': '7px',
        'borderBottomRightRadius': '7px',height:'600px', width:'600px'}}
        footer={[
            <Button className = 'rowClassName1' type="primary" key="Report" style={{backgroundColor: '#2856AF'}} onClick={handleOk}>Report</Button>,
            <Button key="Cancel" onClick={handleCancel}>Cancel</Button>,
          ]}
        >
        <TextArea 
        ref = {inner}
        style={{'borderTopLeftRadius': '7px',
            'borderBottomLeftRadius': '7px',  'borderTopRightRadius': '7px',
            'borderBottomRightRadius': '7px',height:'200px', width:'470px', border:'2px solid'}}
            placeholder="Describe The Issue You Are Facing" allowClear onChange={onChangeTable}
        >
        </TextArea>
        </Modal> 
    </>)
}

export default Tbconfigured