
import ReactImageMagnify from 'react-image-magnify';
export function ImageMagnify(props) {
  const currImgUrl = props?.images[props?.currImgIndex]?.['original'];
  //console.log('currImgUrl: ', currImgUrl);
  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: "Product Image",
          isFluidWidth: true,
          // src: "https://placeimg.com/640/480/any",
          src: currImgUrl,
          width: 640,
        },
        largeImage: {
          //src: "https://placeimg.com/640/480/any",
          src: currImgUrl,
          width: 3000,
          height: 3000
        },
        enlargedImagePosition: 'over'
      }}
    />
  )
}