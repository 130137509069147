import React, { useState, useEffect } from 'react'
import Loader from './Loader';
import Sidebar from './Sidebar';
import '../css/Dashboard.css'
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from "react-router-dom";
import { userService } from '../http/user';
import axios from 'axios';
import { BrowserRouter as router } from "react-router-dom";
import { useOrder } from '../hooks/order.hook';
import { Tabs, message, Upload, Button, Input } from 'antd';
import { PlusOutlined, LeftOutlined, SearchOutlined } from '@ant-design/icons';
import TemplateView from './TemplateView/TemplateView';
// import MainView from './TemplateView/MainView';


function Dashboard(props) {

  // const [icon, setIcon] = useState<React | null>(<SetOutline />)

  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  let user_flow_status;
  const [userStatus, setUserStatus] = useState();
  const { refresh } = useOrder();
  const { Dragger } = Upload;

  let flowIndex;
  // console.log("usersub", user.sub, user['http://role'], Object.keys(user))

  const token = async () => {
    const accessToken = await getAccessTokenSilently();
    const tokenObj = {
      token: accessToken,
    }
    localStorage.setItem("accessToken", JSON.stringify(tokenObj));
    // console.log("userrss", accessToken)

  }

  const fetchUserFlow = async () => {
    try {
      let flow = await userService.userFlow({ subId: user.sub });

      if (flow.data.userFlow == 'New') {
        flowIndex = 0;
        setUserStatus(flow.data.userFlow);
        const userFlowObj = {
          "projectId": "",
          "status": userStatus,
          "flow_index": flowIndex
        }
        localStorage.setItem("userFlowObj", JSON.stringify(userFlowObj));
      }

      if (flow.data.userFlow == 'Continue') {
        console.log("user", flow.data);
        user_flow_status = flow.data.message[0]?.user_flow?.status;
        console.log("flow", user_flow_status);
        setUserStatus(user_flow_status);
        if (user_flow_status == 'PENDING') {
          flowIndex = 0;
        }
        if (user_flow_status == 'PROGRESS') {
          flowIndex = 1;
        }
        if (user_flow_status == 'MAPPING-COMPLETED') {
          flowIndex = 1;
        }
        if (user_flow_status == 'QC-PROGRESS') {
          flowIndex = 2;
        }
        if (user_flow_status == 'QC-COMPLETED') {
          flowIndex = 3;
        }

        const userFlowObj = {
          "projectId": flow.data.message[0].user_flow._id,
          "status": user_flow_status,
          "flow_index": flowIndex
        }
        localStorage.setItem("userFlowObj", JSON.stringify(userFlowObj));
        console.log(userFlowObj);
      }
    } catch (e) {
      return e;
    } finally {
      refresh();
    }

  }

  useEffect(() => {
    token();
    fetchUserFlow();
  }, [isLoading]);


  if (isLoading) {
    return <div><Loader /></div>;
  }

  // const Items = [
  //   { label: 'Item 1', key: 'item 1' },
  //   { label: 'Item 2', key: 'Item 2' }
  // ]

  const callback = (key) => {
    // console.log("keyy",typeof key);
    localStorage.setItem('activeTab', key)
    props.activeT(key)
    
  }

  return isAuthenticated && (
    <div>
      <Tabs className='tab' activeKey={props.activeValue} onChange={callback}>
        <Tabs.TabPane className="prog_text" tab="Solutions" key="1" style={{width:"300px"}}>
          <div>
            {userStatus == 'New' &&
              <Link to="/template-converter">
                <div className="card click mt-5">
                  <h6 className='markt_head'>Marketplace Template Converter</h6>
                  <p className='markt_bottom mt-5'>Status: <span className="btn-more qc">NEW</span></p>
                </div>

              </Link>
            }
            {userStatus == 'PENDING' &&
              <Link to="/template-converter">
                <div className="card click mt-5">
                  <h6 className='markt_head'>Marketplace Template Converter</h6>
                  <p className='markt_bottom mt-5'>Status: <span className="btn-more upload">Upload</span></p>
                </div>

              </Link>
            }
            {userStatus == 'PROGRESS' &&
              <Link to="/template-converter">
                <div className="card click mt-5">
                  <h6 className='markt_head'>Marketplace Template Converter</h6>
                  <p className='markt_bottom mt-5'>Status: <span className="btn-more text-warning">Processing</span></p>
                </div>

              </Link>
            }
            {userStatus == 'MAPPING-COMPLETED' &&
              <Link to="/template-converter">
                <div className="card click mt-5">
                  <h6 className='markt_head'>Marketplace Template Converter</h6>
                  <p className='markt_bottom mt-5'>Status: <span className="btn-more text-warning">Processing</span></p>
                </div>

              </Link>
            }
            {userStatus == 'QC-PROGRESS' &&
              <Link to="/template-converter">
                <div className="card click mt-5">
                  <h6 className='markt_head'>Marketplace Template Converter</h6>
                  <p className='markt_bottom mt-5'>Status: <span className="btn-more qc">QC</span></p>
                </div>

              </Link>
            }
            {userStatus == 'QC-COMPLETED' &&
              <Link to="/template-converter">
                <div className="card click mt-5">
                  <h6 className='markt_head'>Marketplace Template Converter</h6>
                  <p className='markt_bottom mt-5'>Status: <span className="btn-more dwnload">DOWNLOAD</span></p>
                </div>

              </Link>
            }




          </div>
        </Tabs.TabPane>
        {/* <Tabs.TabPane className='prog_text' tab="Templates" key="2">
          { user['http://role'].includes('account-manager') && <TemplateView/>} */}
          { user['http://role'].includes('account-manager') && <Tabs.TabPane className='prog_text' tab="Templates" key="2">
           <TemplateView/>

        </Tabs.TabPane>}
      </Tabs>

    </div>


  )
}

export default Dashboard
