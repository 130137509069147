import React from 'react'
import '../css/loader.css'

// const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
function Loader() {
  return (
    <React.Fragment>
    {/* <CircularProgress variant="determinate" value={normalise(props.value)} /> */}
    {/* <LinearProgress variant="determinate" value={normalise(props.value)} /> */}
  </React.Fragment>
  )
}

export default Loader