import { useOrder } from '../../hooks/order.hook';
import { useEffect, useState } from 'react';
import { orderService } from '../../http/order';
import ExitIcon from '../../static/images/exit-arrow.png';
import { Modal, Button } from 'antd';
import { useNavigate } from "react-router-dom";

export function ExitModal(props) {
  //console.log('Exit Modal: ', props);
  const { setOrder, order } = useOrder();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log('handle ok');
    setIsModalVisible(false);
    navigate("/");
  };

  const handleCancel = () => {
    console.log('handle cancel');
    setIsModalVisible(false);
  };

  const rejectOrder = async (orderId) =>{
    console.log('rejecting the order', orderId);
    const response = await orderService.rejectOrder(orderId);
    setIsModalVisible(false);
    setIsFileSelected(false);
    setSelectedFile(null);
    setOrder(null);
    navigate('/');
    return response;
  }

  return (
    <div className="create-order-header flex-box flex-end">
      <button onClick={showModal} style={{width:'100px', height:'50px'}} className="cus-button danger flex-box">
        <img src={ExitIcon} />
        <span>Exit</span>
      </button>
      <Modal 
      title="Save" 
      visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel} 
      okText='Save' 
      cancelText='Cancel'
      footer={[
        <Button type="primary" key="Save" onClick={handleOk}>Save</Button>,
        <Button type="primary" key="Discard" onClick={() => rejectOrder(props?.orderId)}>Discard</Button>,
        <Button type="primary" key="Cancel" onClick={handleCancel}>Cancel</Button>,
      ]}
      >  
        <div style={{color: 'black'}}>
          <p>Are You Sure You Want To Exit ?</p>
          <p>*Please Note this action cannot be undone.</p>
        </div>
      </Modal>
    </div>
  )
}