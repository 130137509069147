import React from 'react';
import './MultiStepProgressHeader.css';
import CompletedTickIcon from '../../static/images/completed_tick.svg';
import PendingTickIcon from '../../static/images/pending_tick.svg';
import SuccessTickIcon from '../../static/images/success_icon_big.svg';

import '../../css/shared.css';

export function MultiProgressHeader(props) {
    let {menus, currentStep} = props; 
    let lineLength = 100 - (menus.length * 10)
    lineLength = lineLength / (menus.length-1)
    return (
        <header className="multi-step-progress__header flex-box flex-start">
            {menus.map((menu, index, array) => {
                return (
                    <React.Fragment key={index}>
                        <section key={index} className="multi-step-progress__step flex-box flex-column-dir flex-sa">
                            { index <= currentStep ? ( index < currentStep ? <img src={SuccessTickIcon} /> : <img src={CompletedTickIcon} /> )  : <img src={PendingTickIcon} /> }
                            <h4 className="step-name">{menu}</h4>
                        </section>
                        {(index != array.length-1) && <span style={{width: lineLength+'%'}} className="step-lines"></span>}
                    </React.Fragment>
                )
            })}
        </header>
    )
}