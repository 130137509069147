import {useEffect, useRef, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import './Gallery.css';
import { Image } from 'antd';
import { ImageMagnify } from './ImageMagnify';

// for react-image-magnify
export function Gallery(props) {
  const refImg = useRef(null);
  const currIndex = refImg?.current?.getCurrentIndex();
  const [currImgIndex, setCurrImgIndex] = useState(0);

  const magnifiedImg = () => {
    return <ImageMagnify {...props} currImgIndex={currIndex}/>
    // return <Image
    // width={200}
    // src={props.images[currImgIndex]['original']}
    // />
  }

  const renderCustomControls = () => {
    setCurrImgIndex(refImg?.current?.getCurrentIndex());
    //return <span>{refImg.current?.getCurrentIndex()}</span>
  }

  const properties = {
    renderItem: magnifiedImg,
    renderCustomControls: renderCustomControls,
    ref: refImg,
    items: props?.images,
    originalHeight: '500px',
    onClick: () => {
      magnifiedImg();
    },
    showIndex: true,
    showFullscreenButton: false,
  }
  return (
    <div className="image-gallery">
      {/* <ImageGallery items={props?.images} originalHeight="500px" /> */}
      <ImageGallery {...properties} />
    </div>
  )
}

// for antd
// export function Gallery(props) {
//   const refImg = useRef(null);
//   const currIndex = refImg.current?.getCurrentIndex();
//   const [currImgIndex, setCurrImgIndex] = useState(0);

//   const magnifiedImg = () => {
//     //return <ImageMagnify {...props} currImgIndex={currIndex}/>
//     return <Image
//     width={200}
//     src={props.images[currImgIndex]['original']}
//     />
//   }

//   const renderCustomControls = () => {
//     setCurrImgIndex(refImg.current?.getCurrentIndex());
//   }

//   const properties = {
//     renderItem: magnifiedImg,
//     renderCustomControls: renderCustomControls,
//     ref: refImg,
//     items: props.images,
//     originalHeight: '500px',
//     onClick: () => {
//       magnifiedImg();
//     },
//     showIndex: true,
//     showFullscreenButton: false,
//   }
//   return (
//     <div className="image-gallery">
//       {/* <ImageGallery items={props.images} originalHeight="500px" /> */}
//       <ImageGallery {...properties} />
//     </div>
//   )
// }