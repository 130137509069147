import Login from './components/Login'
import React, { useEffect, useState } from "react";
import {
  Route, Routes
} from "react-router-dom";
import Dashboard from './components/Dashboard';
import StorageDashboard from './components/StorageDashboard/StorageDashboard'
import { TemplateConverter } from './pages';
import { AppShell } from './components';

import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { OrderProvider } from './contexts/order.context';
import { RecoilRoot } from 'recoil';

import "antd/dist/antd.css";
import Feedback from './pages/Feedback';

const BoardToken = 'YOUR_BOARD_TOKEN';

function App() {

  if(localStorage.getItem('activeTab') === null){
    console.log("in the active tab")
    localStorage.setItem('activeTab', '1')
  };
  const { isAuthenticated } = useAuth0();
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab'));

  useEffect(() =>{

  }, [activeTab])

  return (
    <RecoilRoot>
      <OrderProvider>
        <div className="app-container">
          <Login/>
          {isAuthenticated && <Routes>
            {/* <Route path="upload" element={<Upload />} />
              <Route path="loader" element={<Loader />} /> */}
            <Route path="" element={<AppShell activeT={setActiveTab}/>}>
              <Route path="" element={<Dashboard activeT={setActiveTab} activeValue={activeTab}/>}/>
              <Route path="template-converter" element={<TemplateConverter/>}/>
              <Route path="feedback" element={<Feedback/>}/>
              <Route path="storage-config" element={<StorageDashboard/>}/>
            </Route>
          </Routes>}
        </div>
      </OrderProvider>
    </RecoilRoot>
  );
}

export default App;
