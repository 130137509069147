import { useState, useEffect } from 'react';
import './TemplateConverter.css';
import { MultiStepProgress, RequestCreation, EditorDashboard } from '../../components';
import DownloadFile from '../../components/DownloadFile/DownloadFile';
import Processing from '../../components/Process/Processing';
import { useAuth0 } from '@auth0/auth0-react';
import { userService } from '../../http/user';
import { useOrder } from '../../hooks/order.hook';
import {useNavigate} from "react-router-dom";


export function TemplateConverter() {
    const [currentStep, setCurrentStep] = useState(0);
    const [orderDetails, setOrderDetails] = useState(null);
    // Note: Use this to store all misc info when navigating between steps
    const [stepStateVar, setStepStateVar] = useState({});
    const { setOrder, setBatches } = useOrder();
    const { user } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const userFlow = JSON.parse(localStorage.getItem("userFlowObj"))
        if (userFlow && userFlow.flow_index) {
            setCurrentStep(userFlow.flow_index);
        }
        return ()=>{
            navigate('/')
        }
    }, []);

    useEffect(async () => {
        const userFlow = await userService.userFlow({subId:user.sub})
        const response = userFlow.data;
        console.log("message",response);
        if(response.status === true) {
            if(response.message && response.message[0]) {
                const orderDetails = response.message[0].user_flow;
                setBatches(response.message[0].batches);
                setOrder(orderDetails);
            }
        }
    }, [])

    const moveToNextStep = () => {
        setCurrentStep(currentStep+1);
    }


    return (
        <main className="template-converter">
            <MultiStepProgress menu={['Upload', 'Processing', 'QC', 'Download']} currentStep={currentStep}>
                <RequestCreation moveToNextStep={moveToNextStep} />
                <Processing moveToNextStep={moveToNextStep} />
                <EditorDashboard moveToNextStep={moveToNextStep} />
                <DownloadFile />
            </MultiStepProgress>
        </main>
    )
}