import { notification } from 'antd';
import "antd/dist/antd.css";

export const Notification = (type, message, description, duration=4.5) => {
  notification.open({
    message,
    description,
    duration,
    type
  });
};
