import React from 'react';
import PDFFileIcon from '../../static/images/pdf_file.svg';
import CloseIcon from '../../static/images/close_icon.svg';

const ExcelUpload = (props) => {

    props.file.sizeInMB = (props.file.size / 1048576).toPrecision(2);
    return <>
        <div className='excelFile'>
            <div>
                <img src={PDFFileIcon} style={{padding:"2px 10px"}}/>
                <span style={{paddingTop:"3px", color:"#565656", fontSize:"18px"}}> &nbsp; {props.file.name}</span></div>
            <div>
                <span style={{color: '#8A8B8C', paddingTop:"3px"}}>{props.file.sizeInMB} MB &nbsp; &nbsp;</span>
                <img onClick={props.removeFile} src={CloseIcon} />
            </div>
        </div>
    </>
}

export default ExcelUpload;