import { httpClient } from ".";

async function storageTemplate(data) {
    return httpClient.post('/api/conf-storage/store_mapping_format', data)
    .then((response) => {
      return response
    })
    .catch(err => {
      return err
    })
}

export const storeService = {
    storageTemplate
}