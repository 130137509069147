import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './Loader';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Image, Row, Spin } from 'antd';
import Logo from '../static/logo.svg';
import Gimmick from '../static/images/gimmick.svg';
import Ellipse from '../static/images/ellipse.svg';

function Login() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 64 }} spin/>}>
      <div style={{ height: '100vh', width: '100%' }}/>
    </Spin>;
  }
  return !isAuthenticated && (<div className="Login container">
    <Row>
      <Col span={12}>
        <div style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 2
        }}>
          <img src={Logo} alt={'logo'} height={'75px'} width={'75px'} style={{position: 'absolute', top: 50}}/>
          {/*<div style={{ color: '#7E7E7E', fontSize: '0.9rem', marginTop: 10 }}>Cataloging Solutions</div>*/}
          <div style={{
            fontWeight: 'bold',
            color: '#7E7E7E',
            marginTop: '1.5rem',
            fontSize: '1.5rem',
            whiteSpace: 'nowrap',
          }}>
            Enabling faster, easier Product Listing<br/> on Marketplaces
          </div>
          <button className="btn loginBtn mt-3" onClick={()=>loginWithRedirect()}>Login</button>
        </div>
      </Col>
      <Col span={12}>
        <div style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 50px',
          position: 'relative',
        }}>
          <img src={Gimmick} alt={'logo'} width={'500px'} height={'500px'} style={{ zIndex: 10 }}/>
          <div style={{ color: '#7E7E7E', fontSize: '1.2rem', marginTop: -120, textAlign: 'center', zIndex: 10 }}>
            Generate ready-to-be-listed templates using
            auto-conversion technology
          </div>
          <img src={Ellipse} style={{
            position: 'absolute',
            top: '45%',
            left: '48%',
            width: '120vh',
            height: '120vh',
            transform: 'translate(-45%, -50%)',
            borderRadius: '20px',
            zIndex: 0,
          }}/>
        </div>
      </Col>
    </Row>
  </div>)
}

export default Login