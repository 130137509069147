import { httpClient } from ".";

async function userFlow(data) {
    return httpClient.post('userFlow', data).then((response) => {
      localStorage.setItem('CANNY_TOKEN', response.data['canny_token']);
      return response
    })
}

export const userService = {
    userFlow
}