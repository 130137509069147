import React, { useState } from "react";
import { Button, Modal } from "antd";
import { CheckCircleFilled } from '@ant-design/icons';
import { orderService } from '../../http/order';
import { Notification } from '../Notifications/Notification'

const ShowConfirm = (props) => {
    
    const [isHovering, setIsHovering] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleMouseOver = () =>{
        setIsHovering(true)
    }

    const handleMouseOut = () =>{
        setIsHovering(false)
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = async () => {
        console.log(props.check, props.children);
        const report_data = {
          Request: `${props.children}`,
          sourceclient: props.sourceM,
          destinationclient : props.destinationM,
          sourcetemplate :props.check['template'],
          destinationtemplate: "In Unconfigured",
          splitAttribute: props.check['Split Attribute'],
          splitAttributeValue: props.check['Split Attribute'] === "NA"? "NA" : props.children
        }
        const res = await orderService.request(report_data)
        if(res.data.message === "Done"){
          Notification('success', `${props.children}` ,'Your request has been logged')
        }
        else {
          Notification('warning', `${props.children}` ,'You have already requested it once! ')
        }
        console.log("response", res);
        console.log("data", report_data)
        // props.sd(data)
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };


    return (<div onMouseOver={()=>{
            handleMouseOver()
        }} onMouseOut = {() => {
            handleMouseOut()
        }}>
            {isHovering ? <Button size="small" onClick={showModal} type="primary" style={{background:"#2856AF"}}>Request</Button>: <span style={{height:"30px"}}>{props.children}</span>}

        <Modal
        className="rowClassName1"
        title="Request Confirmation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Request'
        cancelText='Cancel'
        closeIcon=<CheckCircleFilled style={{color:"#18A41D"}} />
        style={{
          'borderTopLeftRadius': '7px',
          'borderBottomLeftRadius': '7px', 'borderTopRightRadius': '7px',
          'borderBottomRightRadius': '7px', height: '600px', width: '600px'
      }}
      footer={[
        <Button className='rowClassName1' type="primary" key="Report" onClick={handleOk} style={{backgroundColor: '#2856AF'}}>Request</Button>,
        <Button key="Cancel" onClick={handleCancel}>Cancel</Button>,
      ]}
    >
      <span>
        <p>Are you sure you want to raise a new mapping request for <strong>{props.check['template']}</strong> : <strong><u>{props.children}</u></strong> ? <br></br>
          If so, we will get back to you shortly on this.
        </p>
      
      </span>
    </Modal>
    </div>)

}

export default ShowConfirm;