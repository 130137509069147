import { Button, Modal, Input, Alert } from 'antd';
import React, { useState } from 'react';
import { orderService } from '../../http/order';

const PriceModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isAlert, setIsAlert] = useState(false);
  const [priceValue, setPriceValue] = useState();
  
  const checkAlert = (check) => {
    setIsAlert(check);
  };

  const handleOk = () => {

    if (priceValue >= 0){
      setIsModalVisible(false);
      orderService.priceDeduction(props.orderId,parseInt(priceValue)).then((val) => {
        props.loadSku();
        console.log("Price Successfuly Send", val)})
    }
    else{
      console.log("alert", isAlert)
      checkAlert(true)

    }
    
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const alertClose = () => {
    checkAlert(false);
  }

  const onEdit = (event) => {
    const {name, value} = event.target;
    
    if (name === "price"){
      console.log("Its fine", value);
      setPriceValue(value)
       
    }
  }

  return (
    <>
      {localStorage.getItem('priceModal') &&
      <Modal className="priceModal" title="Price Deduction" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button className="btn-confirm" type="primary" key="Confirm" onClick={handleOk}>Confirm</Button>,
        <Button className="btn-cancel" key="Cancel" onClick={handleCancel}>Cancel</Button>,
      ]}>
    { isAlert &&
    <Alert message="Negative values are not accepted. Please retry" type="error" closable showIcon afterClose = {alertClose}
    />}
        <p className="subDesc">Enter amount in INR to be deducted from Price of all units</p>
        <Input className="priceField" name= "price" type= "number" placeholder="0" value={priceValue} prefix={<span style={{fontFamily: "sans-serif"}}>₹</span>} onChange={onEdit} ></Input>
      </Modal>}
    </>
  );
};

export default PriceModal;