import './PanelFooter.css';
import { orderService } from '../../../../http/order';
import { Notification } from '../../../Notifications/Notification';
import { UpOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, message } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { qcFilterState } from '../../../../atoms/qc.atom';


export function PanelFooter(props) {
  const [qcFilter, setQcState] = useRecoilState(qcFilterState);

  async function handleSkipMenuClick(e) {
    if (e.key == 'skipqclater') {
      const res = await orderService.skipSKU(props.skuId);
      if (res && res['data'] && res['data']['status'] === 'QC-SKIPPED') {
        Notification('success', 'Unit Skipped', 'You can work on this unit after submitting remaining units in this batch');
      } else {
        Notification('error', 'Skip Failed', 'Unable to skip unit. Please reach out to us at support@textmercato.com for more assistance');
      }
      props.loadSku();
    }
    if (e.key == 'skipqc') {
      console.log("skip sku completely even without filling mandatory attributes.");
      const res = await orderService.skipSKUCompletly(props.skuId);
      const pendingCount = props.totalCount - props.completedCount - 1;
      if (pendingCount == 0) {
        const res = await orderService.submitSKU(props.skuId, props.qcData, true);
      }
      if (res && res['data'] && res['data']['status'] === 'QC-SKIPPED-COMPLETLY') {
        Notification('success', 'Unit Skipped Completely', 'QC skipped for this unit. Product data of this unit will still be included in the output template and can  be separately downloaded as well in the next step.');
      } else {
        Notification('error', 'Unit Skipped Failed', 'Unable to skip unit. Please reach out to us at support@textmercato.com for more assistance');
      }
      props.loadSku();
    }

  }

  const skipMenu = (
    <Menu
      onClick={handleSkipMenuClick}
      items={[
        {
          key: 'skipqc',
          label: 'Skip QC of this unit'
        },
        {
          key: 'skipqclater',
          label: 'QC this unit later',
        },
      ]}
    />
  );
  const isImageField = (attributeName) => {
    // though these are mandatory they are non-editable so ignore image fields
    const lowerCase = attributeName.toLowerCase();
    return lowerCase.includes('image');
  }

  return (
    <div className="panel-footer">

      <Dropdown overlay={skipMenu}>
        <a style={{ marginRight: 50, fontFamily: 'Poppins', fontWeight: 500, fontSize: 15, marginTop: 10 }}>
          <Space>
            Skip Options
            <UpOutlined/>
          </Space>
        </a>
      </Dropdown>

      <button
        className='submit-btn'
        onClick={async () => {
          props.attemptSubmit();
          for (let op of props.outputFormat) {
            //console.log('op', op.attributeName);
            if (!op?.process_rule?.optional && !props.qcData[op.attributeName] && props.qcData[op.attributeName] !== 0 && !isImageField(op.attributeName)) {
              //console.log('validation error:',op.attributeName, props, props.qcData[op.attributeName]);
              Notification('error', 'Validation Error', 'Please fill all mandatory fields (*)');
              return;
            }
          }
          const res = await orderService.submitSKU(props.skuId, props.qcData, false);
          if (res && res['data'] && res['data']['status'] === 'QC-COMPLETED') {
            const eagerlySubmittedCounts = qcFilter ? res['data']['eagerlySubmittedUnits'] : 0;
            const pendingCount = props.totalCount - props.completedCount - eagerlySubmittedCounts - 1;

            if (qcFilter && eagerlySubmittedCounts > 0) {
              Notification('success', 'Unit submitted', (<>
Unit submitted.<br/>
As 'Filtered Units' feature has been enabled, we have auto-completed {eagerlySubmittedCounts} more similar units.<br/>
{pendingCount} filtered units remaining in this batch
</>), 10);
            } else {
              Notification('success', 'Unit submitted', `Unit Submitted, ${pendingCount} unit${pendingCount > 1 ? 's' : ''} remaining in this batch`);
            }
          } else {
            Notification('error', 'Submission Failed', 'Unable to submit unit. Please reach out to us at support@textmercato.com for more assistance');
          }
          props.loadSku();
          localStorage.removeItem('priceModal');
        }}
      >Submit
      </button>
    </div>
  )
}